import { Heading, Stack, Text } from "@chakra-ui/react";

interface props {
  title: string;
  subtitle?: string;
}

export const Title = ({ title, subtitle }: props) => (
  <Stack
    height="100%"
    flexDirection="column"
    textAlign="center"
    spacing="15"
    maxW="sm"
  >
    <Heading color="gray.900" fontSize="5xl" fontWeight="extrabold">
      {title}
    </Heading>
    {subtitle && <Text color="gray.500">{subtitle}</Text>}
  </Stack>
);
